<template lang="pug">
  confirmation-dialog(
    :value="showing"
    @input="updateShowing"
    title="Updating active status"
    descr="Are you sure you want to change active status?"
    okText="Save"
    failText="cancel"
    @okBtnClicked="update(value)"
  )
    template(v-slot:activator)
      v-btn(
        @click="checkOnUpdate"
        color="newPrimary"
        height="16px"
        :ripple='false'
        x-small
        dark
      ) change status
</template>

<script>
import apiTaskApllications from '@/services/api/api-taskApllications'
import taskApplicationsService from '../core/taskApplications-service'
import errorsMixin from '@/mixins/errors.mixin'

export default {
  mixins: [errorsMixin],

  props: {
    item: Object,
    value: Boolean
  },
  data: () => ({
    showing: false,
  }),

  methods: {
    updateShowing(val) {
      this.showing = val
    },

    toggle() {
      this.showing != this.showing
    },

    checkOnUpdate(val) {
      this.showing = true
    },

    async update() {
      try {
        this.loading = true
        await new taskApplicationsService(apiTaskApllications).updateIsActive(this.item.id, !this.item.is_active)
        this.$notify({type: 'success', text: 'Updated'})
        this.$emit('change', this.item.id)
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    returnVal() {
      this.item.is_active = !this.item.is_active

    }
  },
  components: {
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
  }
}
</script>

<style lang="scss">

.task-app-switch {
  &.v-input--switch.v-input--is-dirty .v-input--switch__thumb {
    transform: translate(25px, 0);
    color: $bg-filled !important;
  }
}

.v-input--switch__thumb {
  height: 14px;
  width: 14px;
  top: calc(50% - 7px);
  box-shadow: unset;
}

</style>
